import Image from "next/image";
import jdihn from "@/public/assets/images/jdihn.png";
import baliprov from "@/public/assets/images/baliprov.png";
import clsx from "clsx";
import Sidebar from "./sidebar";
import Link from "next/link";

const Logo = ({
  variant = "header",
  width = 50,
  height = 50,
  sidebar = false,
}) => {
  const logoClass = clsx("flex", {
    "max-md:hidden": variant === "header",
    "": variant === "footer",
  });
  return (
    <div className={logoClass}>
      <a href="https://jdihn.go.id" target="_blank" rel="noopener noreferrer">
        <Image src={jdihn} width={width} height={height} alt="${logo} Logo" />
      </a>
      <Link href="/">
        <Image
          src={baliprov}
          width={width}
          height={height}
          alt="${logo} Logo"
        />
      </Link>
    </div>
  );
};

export default Logo;
