import axios from "axios";
import { BaseResponseModel } from "@/src/models/baseResponseModel";
import { RelatedLinkModel } from "../models/relatedLinkModel";

export default class RelatedLinkRepository {
  private baseUrl: string;

  constructor() {
    this.baseUrl = (process.env.NEXT_PUBLIC_BASE_API_URL as string) + "v1";
  }

  async fetchAll(): Promise<BaseResponseModel<RelatedLinkModel[]>> {
    try {
      const response = await axios.get(`${this.baseUrl}/related-link`, {
        headers: {
          "X-Internal-Request": "true",
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
