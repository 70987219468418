import React from "react";

interface DialogProps {
  title: string;

  onClose: () => void;
  children: React.ReactNode;
}

const Dialog: React.FC<DialogProps> = ({ title, children, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="rounded-lg bg-white p-6 shadow-lg md:w-full lg:w-1/3">
        <div className="flex items-start justify-between">
          <div className="line-clamp-2 text-xl font-bold">{title}</div>
          <button onClick={onClose} className="text-xl font-bold">
            &times;
          </button>
        </div>
        <div className="mt-4">{children}</div>
      </div>
    </div>
  );
};

export default Dialog;
