import React from "react";
import NewsOldModel from "@/src/models/newsModel";
import { NewsModel } from "@/src/models/newsModel";
import Card from "@/src/components/molecules/card";
import CardNews from "@/src/components/molecules/cardNews";

interface GridViewProps {
  data: NewsModel[];
}

const GridViewNews: React.FC<GridViewProps> = ({ data }) => {
  const gridClasses =
    data.length < 4
      ? "grid-cols-1 sm:grid-cols-2"
      : "grid-cols-1 lg:grid-cols-4";

  return (
    <div className="grid place-items-center items-start justify-center gap-2 gap-y-5 sm:grid-cols-1 lg:grid-cols-4">
      {data.map((item) => (
        <div key={item.id} className="h-full w-full">
          <CardNews news={item} />
        </div>
      ))}
    </div>
  );
};

export default GridViewNews;
