import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Dropdown from "../atoms/dropdown";
import SearchableDropdown from "@/src/components/molecules/searchableDropdown";
import SearchableDropdownReact from "@/src/components/molecules/searchableDropdownReact";

import Constant from "@/src/helpers/constant";
import GenerateYears from "@/src/helpers/generateYears";

interface CardSearchProps {
  onHeightChange: () => void;
  expanded?: boolean;
}

const CardSearch: React.FC<CardSearchProps> = ({
  onHeightChange,
  expanded = false,
}) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [type, setType] = useState<{ value: string; label: string } | null>(
    null,
  );
  const [year, setYear] = useState<{ value: string; label: string } | null>(
    null,
  );
  const [category, setCategory] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [field, setField] = useState<{ value: string; label: string } | null>(
    null,
  );
  const [categoryApiEndpoint, setCategoryApiEndpoint] = useState(
    `${Constant.BASE_API_URL}v1/master/categories`,
  );

  const yearList = GenerateYears(2010);

  useEffect(() => {
    if (type) {
      setCategoryApiEndpoint(
        `${Constant.BASE_API_URL}v1/master/categories?type=${type.value}`,
      );
    } else {
      setCategoryApiEndpoint(`${Constant.BASE_API_URL}v1/master/categories`);
    }
  }, [type]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    setTimeout(onHeightChange, 0); // Call the height change callback after the state update

    if (isOpen == false) {
      setCategory(null);
      setField(null);
    }
  };

  const searchHandle = () => {
    const queryParams: { [key: string]: string } = {};

    if (title) queryParams.term = encodeURIComponent(title);
    if (type) queryParams.type = type.value;
    if (year) queryParams.year = year.value;
    if (category) queryParams.category = category.value;
    if (field) queryParams.field = field.value;

    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&");

    router.push(`/pencarian?${queryString}`);
  };

  return (
    <div className="mb-2 block justify-center md:w-full xl:w-96">
      <div className="flex flex-wrap rounded-xl bg-white p-4 font-sans shadow-xl">
        <h2 className="text-base font-bold">Cari Peraturan</h2>
        <div className="form container mx-auto mt-4">
          <div className="mb-2">
            <label
              htmlFor="example-textfield"
              className="mb-2 block text-sm font-semibold text-gray-700"
            >
              Judul
            </label>
            <input
              id="example-textfield"
              type="text"
              className="w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 focus:outline-none"
              placeholder="Ketik judul praturan"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="example-textfield"
              className="mb-2 block text-sm font-semibold text-gray-700"
            >
              Tipe Peraturan
            </label>
            <SearchableDropdownReact
              // dataList={typeList}
              apiEndpoint={`${Constant.BASE_API_URL}v1/master/type`}
              placeholder="-Pilih tipe peraturan-"
              onChange={(option) => setType(option)}
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="example-textfield"
              className="mb-2 block text-sm font-semibold text-gray-700"
            >
              Tahun Peraturan
            </label>
            {/* <Dropdown
              dataList={yearList}
              placeholder="-pilih tahun peraturan-"
            /> */}
            <SearchableDropdownReact
              dataList={yearList}
              placeholder="Semua Tahun"
              onChange={(option) => setYear(option)}
            />
          </div>
          <div className={`${isOpen ? "" : expanded ? "" : "hidden"}`}>
            <div className="mb-2">
              <label
                htmlFor="example-textfield"
                className="mb-2 block text-sm font-semibold text-gray-700"
              >
                Kategori Peraturan
              </label>
              {/* <Dropdown dataList={dataList} placeholder="Semua Kategori" /> */}
              <SearchableDropdownReact
                apiEndpoint={categoryApiEndpoint}
                placeholder={"Semua Kategori"}
                onChange={(option) => setCategory(option)}
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="example-textfield"
                className="mb-2 block text-sm font-semibold text-gray-700"
              >
                Bidang Hukum
              </label>
              {/* <Dropdown dataList={dataList} placeholder="Semua Bidang Hukum" /> */}
              <SearchableDropdownReact
                apiEndpoint={`${Constant.BASE_API_URL}v1/master/fields`}
                placeholder={"Semua Bidang Hukum"}
                onChange={(option) => setField(option)}
              />
            </div>
          </div>

          <div className="button-group mt-6 flex flex-col justify-center gap-2">
            {expanded == false && (
              <button
                className="w-full rounded-xl border border-[#F5F5F5] bg-white px-4 py-2 text-[#737373]"
                onClick={toggleOpen}
              >
                <div className="flex items-center justify-center gap-2">
                  {isOpen ? "Tutup Detail" : "Cari Lebih Detail"}
                  {isOpen ? (
                    <svg
                      className="ml-2 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 15a1 1 0 01-.707-1.707l4-4a1 1 0 011.414 0l4 4A1 1 0 1117.293 15L14 11.707 10.707 15A1 1 0 0110 15z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="ml-2 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.292 7.707a1 1 0 011.414 0L10 11.001l3.293-3.294a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </div>
              </button>
            )}

            <button
              className="w-full rounded-xl bg-primary px-4 py-2 text-white"
              onClick={searchHandle}
            >
              <div className="flex items-center justify-center gap-2">
                Cari
                {/* <img
                        src="assets/icons/u_search.png"
                        className="h-5 w-5"
                      ></img> */}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="11"
                    cy="11"
                    r="7"
                    stroke="white"
                    strokeWidth="2"
                  />
                  <line
                    x1="15.5"
                    y1="15.5"
                    x2="20"
                    y2="20"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSearch;
