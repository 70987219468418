import React, { useState, useRef, useEffect } from "react";
import DialogWithBackdrop from "../molecules/dialogBackdrop";
import Star from "../atoms/star";
// import ReCaptcha from "../atoms/recaptchaV3";
import ReCaptcha from "../atoms/recaptcha";
import { useFormState } from "react-dom";
import axios from "axios";
import StarInput from "../atoms/starInput";
import { getOrCreateIdentifier } from "../../helpers/identifierHelper";
import { toast } from "react-toastify";
import { PostRatingModel } from "@/src/models/ratingModel";
import RatingRepository from "@/src/repositories/ratingRepository";

interface RatingDialogProps {
  onClose: () => void;
}

const RatingDialog: React.FC<RatingDialogProps> = ({ onClose }) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [identifier, setIdentifier] = useState<string | null>(null);
  const [rating, setRating] = useState<number>(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [hasError, setHasError] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [errors, setErrors] = useState<{
    name?: string;
    email?: string;
    comment?: string;
  }>({});

  const ratingRepository = new RatingRepository();

  const handleVerifyRecaptcha = (token: string | null) => {
    setRecaptchaToken(token);
  };

  useEffect(() => {
    const id = getOrCreateIdentifier();
    setIdentifier(id);
  }, []);

  //Sanitize Input
  const sanitizeInput = (input: string) => {
    const scriptTagRegex = /<script.*?>.*?<\/script>/gi;
    const onEventRegex = /on\w+=".*?"/gi; // e.g., onClick, onError

    // Remove script tags and on* event handlers
    let sanitizedInput = input.replace(scriptTagRegex, "");
    sanitizedInput = sanitizedInput.replace(onEventRegex, "");
    return sanitizedInput;
  };

  //Validate the form
  const validate = () => {
    const newErrors: { name?: string; email?: string; comment?: string } = {};

    if (!name || name.length > 36) {
      newErrors.name = "Nama harus diisi dan tidak lebih dari 36 karakter."; // Length check
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      newErrors.email = "Email harus diisi.";
    } else if (!email || !emailRegex.test(email)) {
      newErrors.email = "Format email tidak valid.";
    }

    const sanitizedComment = sanitizeInput(comment);
    if (!comment || comment.length > 255 || comment.length == 0) {
      newErrors.comment =
        "Komentar harus diisi dan tidak lebih dari 255 karakter.";
    } else if (sanitizedComment !== comment) {
      newErrors.comment = "Komentar mengandung konten yang tidak valid.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // const [state, submitRating] = useFormState(postRating, initialState);
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitted(true);

    if (!validate()) return;

    if (!recaptchaToken) {
      toast.info("Silahkan lengkapi reCaptcha");
      return;
    }

    /** Check Captcha Via API not use 
    // const response = await fetch("/api/verify-recaptcha", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ token: recaptchaToken }),
    // });

    // const result = await response.json();
    // if (!result.success) {
    //   toast.error("Verifikasi gagal, silahkan ulang kembali");
    //   return;
    // }
    */

    if (!formRef.current) return;

    if (rating === 0) {
      setHasError(true);
      toast.info("Silahkan beri rating sebelum mengirim ulasan.");
      return;
    }

    const ratingInputs = formRef.current.querySelectorAll<HTMLInputElement>(
      'input[type="radio"][name="rating"]',
    );
    const ratingInputsLength = ratingInputs.length;
    const selectedRating = Array.from(ratingInputs).find(
      (input) => input.checked,
    )?.value;

    // alert(rating);

    const postData: PostRatingModel = {
      captcha: recaptchaToken,
      identifier: identifier as string,
      rating: Number(rating),
      name,
      email,
      comment,
      browser: navigator.userAgent,
      // ip: "", // you can fetch IP address separately if required
    };

    try {
      const post = await ratingRepository.post(postData);
      // console.log(post);

      if (post.code === 200 && post.status === "OK") {
        toast.success("Ulasan anda sudah terkirim. Terima Kasih");
        window.dispatchEvent(new Event("refreshRating"));
        onClose();
      }
    } catch (e) {
      toast.error("Gagal memberi ulasan");
    }

    if (formRef.current) {
      // alert(`Terima kasih telah memberi rating: ${ratingInputs} kepada kami.`);
      // toast.success("Ulasan anda sudah terkirim. Terima Kasih");
      // window.location.reload();
    }
  };

  return (
    <DialogWithBackdrop
      title="Nilai Situs JDIH DPRD Provinsi Bali"
      onClose={onClose}
    >
      <form
        className="flex flex-col items-start"
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <div className="-rotate-360 mx-4 mb-3 mt-2 scale-150 transform-gpu px-4">
          <StarInput
            rating={0}
            disable={false}
            setRating={setRating}
            hasError={hasError}
          />
        </div>
        <div className="my-2 w-full">
          <input
            type="text"
            className={`mt-2 w-full border p-2 ${
              isSubmitted && errors.name ? "border-red-500" : ""
            }`}
            placeholder="Nama"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={validate}
            // required
          />
          {isSubmitted && errors.name && (
            <small className="text-red-500">{errors.name}</small>
          )}
        </div>
        <div className="my-2 w-full">
          <input
            type="text"
            className={`w-full border p-2 ${
              isSubmitted && errors.email ? "border-red-500" : ""
            }`}
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            // required
          />
          {isSubmitted && errors.email && (
            <small className="text-red-500">{errors.email}</small>
          )}
        </div>
        <div className="my-2 w-full">
          <textarea
            className={`w-full border p-2 ${
              isSubmitted && errors.comment ? "border-red-500" : ""
            }`}
            placeholder="Komentar"
            value={comment}
            onChange={(e) => setComment(sanitizeInput(e.target.value))}
            // required
          ></textarea>
          {isSubmitted && errors.comment && (
            <small className="text-red-500">{errors.comment}</small>
          )}
        </div>

        <div className="my-2 w-full">
          <ReCaptcha onVerify={handleVerifyRecaptcha} />
          {/* <ReCaptcha action="submit_form" onVerify={handleVerify} /> */}
        </div>
        <button
          type="submit"
          className="w-full rounded-md bg-primary py-2 text-white"
        >
          Kirim Ulasan
        </button>
      </form>
    </DialogWithBackdrop>
  );
};

export default RatingDialog;
