import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface BarChartProps {
  data: any;
  title: string;
}

const BarChart: React.FC<BarChartProps> = ({ data, title }) => {
  const maxDataValue = Math.max(...data.datasets.flatMap((d: any) => d.data));
  const maxScale = Math.ceil(maxDataValue * 1.1);

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: maxScale,
        ticks: {
          stepSize: 1, // Ensure steps of 1
          //   callback: (value) => Math.floor(value as number), // Format as integer
        },
      },
    },
    plugins: {
      legend: { position: "top" as const },
      title: {
        display: true,
        text: title,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;
