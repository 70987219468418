import axios from "axios";
import {
  BaseResponseModel,
  BaseMessageModel,
} from "../models/baseResponseModel";
import { LawFileModel } from "../models/lawFileModel";

export type PostData = {
  identifier: string;
};

export default class FileRepository {
  private baseUrl: string;

  constructor() {
    this.baseUrl = (process.env.NEXT_PUBLIC_BASE_API_URL as string) + "v1";
  }

  async fetchDownload(
    id_legislation: string,
    id_doc: string,
    data: PostData,
  ): Promise<any> {
    try {
      const response = await axios.post(
        `${this.baseUrl}/laws/detail/${id_legislation}/download/${id_doc}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Internal-Request": "true",
          },
        },
      );

      const responseData:
        | BaseResponseModel<Record<string, unknown>>
        | BaseMessageModel = response.data;

      return responseData;
    } catch (error) {
      throw error;
    }
  }

  async exportAbstract(id_legislation: string, id: string): Promise<any> {
    try {
      const response = await axios.post(
        `${this.baseUrl}/laws/detail/${id_legislation}/abstract/${id}`,
        {
          header: { "X-Internal-Request": "true" },
        },
        {
          responseType: "blob",
        },
      );

      const url = window.URL.createObjectURL(response.data);
      const newTab = window.open(url, "_blank");
      if (!newTab) {
        console.error("Failed to open new tab");
      }
    } catch (error) {
      throw error;
    }
  }
}
