import React, { useState, useRef, useEffect } from "react";
import Link from "next/link";
import Navbar from "@/src/components/organisms/navbar";
import Carousel from "@/src/components/organisms/carousel";
import Card from "@/src/components/molecules/card";
import Footer from "@/src/components/organisms/footer";
import GridViewLegislation from "@/src/components/templates/gridViewLegislation";
import GridViewNews from "@/src/components/templates/gridViewNews";
import { data, news } from "@/src/repositories/localDataRepository";
import BaseLayout from "@/src/components/templates/baseTemplate";
import NewDocuments from "@/src/components/templates/newDocuments";
import PopularDocuments from "@/src/components/templates/popularDocuments";
import SectionContact from "@/src/components/templates/sectionContact";
import SectionNews from "@/src/components/templates/sectionNews";
import ContactMail from "@/src/components/molecules/contactMail";
import BarChartStatistic from "@/src/components/molecules/barChartStatistic";

const HomePage = () => {
  // const images = ["image-1.jpg", "image-22.jpg"];
  const images = ["Main-DPRD-compress-high.jpg"];

  return (
    <BaseLayout>
      <section className="flex h-auto w-full">
        <Carousel images={images} />
      </section>
      <section className="newest-doc p-4">
        <NewDocuments />
      </section>
      <section className="popular-doc p-4">
        <PopularDocuments />
      </section>
      <section className="news container mx-auto mt-6">
        <SectionNews />
      </section>
      <section className="container mx-auto mb-2 mt-12 justify-center">
        <ContactMail />
      </section>
      <section className="mx-auto mb-8 mt-12 justify-center p-4">
        <BarChartStatistic />
      </section>
    </BaseLayout>
  );
};

export default HomePage;
