import axios from "axios";
import { BaseResponseModel } from "../models/baseResponseModel";
import { ProfileModel, OperationHourModel } from "../models/generalModel";
import { SocialMediaModel } from "../models/socialMediaModel";

export default class GeneralRepository {
  private baseUrl: string;

  constructor() {
    this.baseUrl = (process.env.NEXT_PUBLIC_BASE_API_URL as string) + "v1";
  }

  async profile(): Promise<BaseResponseModel<ProfileModel>> {
    try {
      const response = await axios.get(`${this.baseUrl}/general/profile`, {
        headers: {
          "X-Internal-Request": "true",
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  async operationalHours(): Promise<BaseResponseModel<OperationHourModel[]>> {
    try {
      const response = await axios.get(
        `${this.baseUrl}/general/operational-hours`,
        {
          headers: {
            "X-Internal-Request": "true",
          },
        },
      );

      return response.data;
    } catch (error) {
      // console.error("Error fetching data:", error);
      throw error;
    }
  }

  async socialMedia(): Promise<BaseResponseModel<SocialMediaModel[]>> {
    try {
      const response = await axios.get(`${this.baseUrl}/general/social-media`, {
        headers: {
          "X-Internal-Request": "true",
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
