export default class Constant {
  static BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

  static BASE_API_URL_V1 = this.BASE_API_URL + "v1";

  static BASE_STORAGE_URL = process.env.NEXT_PUBLIC_STORAGE_URL;

  static HTTP_OK = 200;

  static HTTP_SERVER_ERROR = 500;

  static HTTP_NOT_FOUND = 404;

  static HTTP_UNAUTHORIZED = 401;

  static HTTP_BAD_REQUEST = 400;

  static HTTP_NOT_ACCEPTABLE = 406;

  static HTTP_TOO_MANY_REQUEST = 429;

  static HTTP_FORBIDDEN = 403;

  static HTTP_UNPROCESSABLE_CONTENT = 422;

  static FILTER_SEARCH_AUTO_TIMEOUT = 500;

  static DEFAULT_START_YEAR = Number(process.env.DEFAULT_START_YEAR ?? 2021);

  static CURRENT_YEAR = new Date().getFullYear();
}
