import SkeletonChartVertical from "./skeletonChartVertical";

const SkeletonChart = () => {
  const bars = Array.from({ length: 10 }, () => Math.floor(Math.random() * 98));

  const horizontalBar = Array.from({ length: 6 }, () =>
    Math.floor(Math.random() * 98),
  );

  return (
    <div role="status" className="container mx-auto flex animate-pulse gap-2">
      <div className="mx-auto flex h-[400px] w-full flex-row items-end justify-start gap-7 rounded bg-slate-200 pb-4 pl-6">
        {bars.map((height, index) => (
          <div
            key={index}
            className="w-8 bg-slate-300"
            style={{
              height: `${height}%`, // Set random height
            }}
          />
        ))}
      </div>
      <div className="mx-auto flex h-[400px] w-full flex-col items-start justify-center gap-7 rounded bg-slate-200 pl-6">
        {horizontalBar.map((width, index) => (
          <div
            key={index}
            className="h-8 bg-slate-300"
            style={{ width: `${width}%` }}
          />
        ))}
      </div>
    </div>
  );
};

export default SkeletonChart;
