import axios from "axios";
import { BaseResponseModel } from "../models/baseResponseModel";
import { AnalyticsModel } from "../models/analyticsModel";
import Constant from "../helpers/constant";

export default class AnalyticsRepository {
  private baseUrlV1: string;

  constructor() {
    this.baseUrlV1 = Constant.BASE_API_URL_V1 as string;
  }

  async fetchAnalytics(): Promise<BaseResponseModel<AnalyticsModel>> {
    try {
      const response = await axios.get(`${this.baseUrlV1}/analytics/all`, {
        headers: {
          "X-Internal-Request": "true",
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
