import * as FaIcons from "react-icons/fa";

interface DynamicIconProps {
  name: string;
  className?: string;
}

const DynamicIcon: React.FC<DynamicIconProps> = ({ name, className }) => {
  const iconName =
    `Fa${name.charAt(0).toUpperCase() + name.slice(1)}` as keyof typeof FaIcons;

  const IconComponent = FaIcons[iconName];

  return IconComponent ? <IconComponent className={className} /> : null;
};

export default DynamicIcon;
