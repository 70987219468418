import React from "react";
import DynamicIcon from "../atoms/dynamicIcon";

interface SocialIconListProps {
  data: { id: number; name: string; value: string }[];
}

const SocialIconList: React.FC<SocialIconListProps> = ({ data }) => {
  return (
    <div className="flex space-x-2">
      {data.map((item) => (
        <a
          key={item.id}
          href={item.value}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`Temukan kita di ${item.name}`}
          className="flex h-7 w-7 items-center justify-center rounded-full border text-center"
        >
          {/* <i className="fab fa-facebook-f"></i> */}
          <DynamicIcon name={item.name} />
        </a>
      ))}
    </div>
  );
};

export default SocialIconList;
