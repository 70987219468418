import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface BarChartProps {
  data: any;
  title: string;
}

const HorizontalBarChart: React.FC<BarChartProps> = ({ data, title }) => {
  const maxDataValue = Math.max(...data.datasets.flatMap((d: any) => d.data));
  const maxScale = Math.ceil(maxDataValue * 1.1);

  const options: Partial<ChartOptions<"bar">> = {
    responsive: true,
    indexAxis: "y",
    plugins: {
      legend: { position: "top" },
      title: {
        display: true,
        text: title,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        suggestedMax: maxScale,
        ticks: {
          stepSize: 1, // Ensure steps of 1
          // callback: (value) => Math.floor(value as number), // Format as integer
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default HorizontalBarChart;
