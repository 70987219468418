import axios from "axios";
import {
  BaseResponseModel,
  BaseMessageModel,
} from "../models/baseResponseModel";
import { RatingModel, PostRatingModel } from "../models/ratingModel";

export default class RatingRepository {
  private baseUrl: string;

  constructor() {
    this.baseUrl = (process.env.NEXT_PUBLIC_BASE_API_URL as string) + "v1";
  }
  async fetchRatings(): Promise<BaseResponseModel<RatingModel>> {
    try {
      const response = await axios.get(`${this.baseUrl}/rating`, {
        headers: {
          "X-Internal-Request": "true",
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async post(postData: PostRatingModel): Promise<BaseMessageModel> {
    try {
      // console.log("Post Data:", postData);
      const response = await axios.post<BaseMessageModel>(
        `${this.baseUrl}/rating/store`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Internal-Request": "true",
          },
        },
      );

      // console.log(response.data);

      return response.data;
    } catch (error: any) {
      if (error.response) {
        throw new Error(
          `Request failed with status ${error.response.status}: ${JSON.stringify(error.response.data)}`,
        );
      } else if (error.request) {
        // console.log("No response received", error.request);
        throw new Error("No response received from server.");
      } else {
        // console.log("Error setting up request", error.message);
        throw new Error("Error in request setup: " + error.message);
      }
    }
  }

  async check(id: string): Promise<boolean> {
    try {
      const response = await axios.get(`${this.baseUrl}/rating/${id}/check`, {
        headers: {
          "X-Internal-Request": "true",
        },
      });

      if (response.data.status === "DUPLICATE") {
        return false;
      }

      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          // Optionally log the 401 status here, but avoid letting Axios log it by default
          return false;
        }
      }

      return false;
    }
  }
}
