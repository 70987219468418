import React from "react";
import Link from "next/link";
import NewsOldModel from "@/src/models/newsModel";
import { DateHelper } from "@/src/helpers/dateHelper";
import TruncateText from "@/src/helpers/truncateText";
import Image from "next/image";
import { NewsModel } from "@/src/models/newsModel";

interface NewsProps {
  news: NewsModel;
}

const CardNews: React.FC<NewsProps> = ({ news }) => {
  const thumbnailPath =
    news.news_files && news.news_files.length > 0
      ? news.news_files[0].file_path
      : null;
  // const imageurl = `${process.env.NEXT_PUBLIC_STORAGE_URL}${news?.thumbnail?.[0].path}`;

  const baseURL = process.env.NEXT_PUBLIC_STORAGE_URL;
  const imageURL = thumbnailPath ? `${baseURL}${thumbnailPath}` : null;

  // const detailLink = () => `news/${news.slug}`;

  return (
    <div className="mx-auto flex h-full w-full flex-col justify-between overflow-hidden rounded-xl border bg-white p-2 shadow-sm lg:p-4">
      {thumbnailPath ? (
        <img
          src={thumbnailPath}
          alt={news.title}
          className="center h-72 w-full rounded-xl object-cover"
        />
      ) : (
        <img
          src="/assets/images/empty-image.jpg"
          alt="cover"
          className="center h-72 w-full rounded-xl object-cover"
        />
      )}
      <div className="mb-4 mt-2 h-20 min-h-[3rem] py-2">
        <div className="line-clamp-3 text-xl font-bold hover:underline">
          <Link href={`/berita/${news.slug}`}>{news.title}</Link>
        </div>
      </div>
      <div className="mt-2 flex gap-2 py-2">
        <Image
          priority
          src="assets/icons/svgs/u_calendar-alt.svg"
          height={28}
          width={28}
          alt="Icon Calendar"
        />
        <span>{DateHelper(news.published_at)}</span>
      </div>
      <div className="h-[6rem] w-full">
        {news.content ? (
          <p
            className="line-clamp-4 overflow-hidden text-ellipsis break-words text-base text-gray-700"
            dangerouslySetInnerHTML={{ __html: news.content }}
            style={{ width: "100%" }}
          />
        ) : (
          <p
            className="line-clamp-4 overflow-hidden text-ellipsis break-words text-base text-gray-700"
            style={{ width: "100%" }}
          >
            -
          </p>
        )}
      </div>
    </div>
  );
};

export default CardNews;
