import RelatedLink from "../atoms/relatedLink";

interface RelatedLinkProps {
  data: { id: number; name: string; url: string }[];
}
const RelatedLinkList: React.FC<RelatedLinkProps> = ({ data }) => {
  return (
    <ul className="space-y-2">
      {data.map((item) => (
        <li key={item.id}>
          <RelatedLink url={item.url} text={item.name} />
        </li>
      ))}
    </ul>
  );
};

export default RelatedLinkList;
