import axios from "axios";

export interface CategoryResponse {
  data: {
    series: {
      name: string;
      data: { x: number; y: number }[];
      type: string;
    }[];
    yaxis: {
      title: { text: string };
      opposite: boolean;
      range: number;
    }[];
  };
}

export interface GovAffairResponse {
  data: {
    series: {
      name: string;
      data: { x: string; y: number }[];
      type: string;
    }[];
    yaxis: {
      title: { text: string };
      opposite: boolean;
      range: number;
    };
  };
}

export default class StaticRepository {
  private baseUrl: string;

  constructor() {
    this.baseUrl = (process.env.NEXT_PUBLIC_BASE_API_URL as string) + "v1";
  }

  async categoryChart(
    startYear: number,
    endYear: number,
  ): Promise<CategoryResponse> {
    const response = await axios.get<CategoryResponse>(
      `${this.baseUrl}/statistic/by-category`,
      {
        params: { start_year: startYear, end_year: endYear },
      },
    );
    return response.data;
  }

  async govAffairChart(
    startYear: number,
    endYear: number,
  ): Promise<GovAffairResponse> {
    const response = await axios.get<GovAffairResponse>(
      `${this.baseUrl}/statistic/by-gov-affair`,
      {
        params: { start_year: startYear, end_year: endYear },
      },
    );
    return response.data;
  }
}
