// components/molecules/SearchableDropdown.tsx
import React, { useState, useEffect } from "react";
// import Select from "../atoms/select";
import axios from "axios";
import Select from "react-select";
import { useRouter } from "next/router";

interface Option {
  value: string;
  label: string;
}

interface ApiResponse {
  code: number;
  status: string;
  message: string;
  data: Array<{
    id: number;
    name: string;
  }>;
}

interface SearchableDropdownProps {
  apiEndpoint?: string | null;
  placeholder: string;
  defaultValue?: Option;
  dataList?: Array<{ value: string; label: string }>;
  onChange?: (option: Option | null) => void;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  apiEndpoint,
  placeholder,
  defaultValue = null,
  dataList = [],
  onChange,
}) => {
  const [options, setOptions] = useState<Option[]>(dataList);
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    defaultValue,
  );

  const router = useRouter();
  const { type } = router.query;

  useEffect(() => {
    // Fetch data from API
    if (apiEndpoint) {
      const fetchData = async () => {
        try {
          const response = await axios.get<ApiResponse>(apiEndpoint);
          const data = response.data.data.map((item) => ({
            value: item.id.toString(),
            label: item.name,
          }));
          setOptions(data);

          //if type not null
          // if (type != null) {
          //   const matchedOption = data.find((option) => option.value === type);
          //   if (matchedOption) {
          //     setSelectedOption(matchedOption);
          //   }
          // }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [apiEndpoint]);

  const handleChange = (option: Option | null) => {
    setSelectedOption(option);
    // console.log("Selected option:", option);
    if (onChange) {
      onChange(option);
    }
  };

  return (
    <div className="max-w-full">
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={options}
        isSearchable
        placeholder={placeholder}
        isClearable
        className="w-full"
      />
    </div>
  );
};

export default SearchableDropdown;
