import React, { useState, useEffect } from "react";
import SocialIcon from "@/src/components/molecules/socialIconList";
import SiteTitle from "../molecules/siteTitle";
import StarRating from "../molecules/rating";
import RatingDialog from "./ratingDialog";
import { WebVisitor } from "../atoms/webVisitor";
import { getOrCreateIdentifier } from "../../helpers/identifierHelper";
import { FaSleigh } from "react-icons/fa";
import RatingRepository from "@/src/repositories/ratingRepository";
import { RatingModel } from "@/src/models/ratingModel";
import StarLoading from "../atoms/starLoading";
import { useRatingRefresh } from "@/src/hooks/useRatingRefresh";
import RelatedLinkList from "../molecules/relatedLinkList";
import { SocialMediaModel } from "@/src/models/socialMediaModel";
import { RelatedLinkModel } from "@/src/models/relatedLinkModel";
import GeneralRepository from "@/src/repositories/generalRepository";
import RelatedLinkRepository from "@/src/repositories/relatedLinkRepository";
import { ProfileModel } from "@/src/models/generalModel";
import Constant from "@/src/helpers/constant";

export default function Footer() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [identifier, setIdentifier] = useState<string | null>(null);
  const [isRated, setIsRated] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const currentYear = Constant.CURRENT_YEAR;

  const [rating, setRating] = useState<RatingModel>();
  const [social, setSocial] = useState<SocialMediaModel[]>([]);
  const [relatedLink, setRelatedLink] = useState<RelatedLinkModel[]>([]);
  const [profile, setProfile] = useState<ProfileModel>();

  const ratingRepository = new RatingRepository();
  const generalRepository = new GeneralRepository();
  const relatedLinkRepository = new RelatedLinkRepository();

  const ratingKey = useRatingRefresh();

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    const id = getOrCreateIdentifier();
    setIdentifier(id);
  }, []);

  useEffect(() => {
    const fetchDataProfile = async () => {
      try {
        const response = await generalRepository.profile();
        if (response.data) {
          setProfile(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    const fetchDataSocial = async () => {
      try {
        const response = await generalRepository.socialMedia();
        if (response.data) {
          setSocial(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    const fetchDataLink = async () => {
      try {
        const response = await relatedLinkRepository.fetchAll();
        if (response.data) {
          setRelatedLink(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    const fetchDataRating = async () => {
      setLoading(true);
      if (!identifier) return;

      try {
        const isSubmitted = await ratingRepository.check(identifier as string);
        setIsRated(isSubmitted);

        // After checking, fetch the rating API
        const response = await ratingRepository.fetchRatings();
        if (response.data) {
          setRating(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDataRating();
    fetchDataProfile();
    fetchDataLink();
    fetchDataSocial();
  }, [identifier, ratingKey]);

  return (
    <footer className="mt-2 border-t border-[#D4D4D4] bg-[#F9F9FC] pt-8 text-black">
      {isDialogOpen && <RatingDialog onClose={closeDialog} />}
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          <div className="inline-flex flex-col items-start justify-start gap-5 max-md:gap-2">
            {/* <h5 className="text-lg font-bold">JDIH DPRD Provinsi Bali</h5> */}
            <div className="max-md:font-600 text-neutral-900 max-md:text-2xl">
              <SiteTitle variant="footer" />
            </div>
            <h6 className="font-semi-bold text-md mb-4 text-[#171717]">
              Jaringan Dokumentasi dan Informasi Hukum DPRD Provinsi Bali
            </h6>
            <div className="hidden">
              <h5 className="text-md font-semi-bold mb-1">Kontak Kami</h5>
              <p className="text-gray-400">{profile?.address}</p>
              <p className="text-gray-400">Phone: {profile?.phone}</p>
              <p className="text-gray-400">email: {profile?.email}</p>
            </div>
            <div className="">
              <h5 className="text-md font-semi-bold mb-1">Follow Us</h5>
              <SocialIcon data={social} />
            </div>
          </div>
          <div>
            <h5 className="mb-4 font-semibold text-[#191970]">Tautan</h5>
            <RelatedLinkList data={relatedLink} />
          </div>
          <div>
            <h5 className="mb-3 font-semibold text-[#191970]">Rating Situs</h5>
            {loading ? (
              <div className="flex">
                <StarLoading />
              </div>
            ) : (
              <StarRating
                key={ratingKey}
                total={rating?.total ?? 0}
                count={rating?.count ?? 0}
                rating={rating?.rating ?? 0}
                onClick={openDialog}
                canRating={isRated}
              />
            )}
            <div>
              <h5 className="mb-3 mt-3 font-semibold text-[#191970]">
                Statistik Kunjungan
              </h5>
              <WebVisitor />
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-4 border-t p-4">
        <p className="pb-1 pt-3 text-center text-sm text-[#737373]">
          &copy; Copyright {currentYear}, All Rights Reserved by DISKOMINFOS
          Provinsi Bali
        </p>
      </div>
    </footer>
  );
}
