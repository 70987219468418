import React, { useState, FormEvent } from "react";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const rateLimiter: { [key: string]: number } = {};

const Mail = () => {
  const emailTo = process.env.ADMIN_EMAIL;
  const [emailFrom, setEmailFrom] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [errors, setErrors] = useState<{
    subject?: string;
    emailFrom?: string;
    message?: string;
  }>({});

  const getRandomColorClass = () => {
    const colors = [
      "text-red-500",
      "text-blue-500",
      "text-green-500",
      "text-yellow-500",
      "text-purple-500",
      "text-pink-500",
      "text-indigo-500",
      "text-teal-500",
      "text-orange-500",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const getRandomItalicClass = () => {
    return Math.random() < 0.5 ? "italic" : "";
  };

  const generateMathQuiz = () => {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    const correctAnswer = num1 + num2;

    //Random Color for Question Text
    const num1ColorClass = getRandomColorClass();
    const num2ColorClass = getRandomColorClass();
    const num1ItalicClass = getRandomItalicClass();
    const num2ItalicClass = getRandomItalicClass();

    return {
      question: `<span class="${num1ColorClass} ${num1ItalicClass} font-bold">${num1}</span> + <span class="${num2ColorClass} ${num2ItalicClass} font-bold">${num2}</span> = `,
      correctAnswer,
    };
  };

  //Sanitize Input
  const sanitizeInput = (input: string) => {
    const scriptTagRegex = /<script.*?>.*?<\/script>/gi;
    const onEventRegex = /on\w+=".*?"/gi; // e.g., onClick, onError

    // Remove script tags and on* event handlers
    let sanitizedInput = input.replace(scriptTagRegex, "");
    sanitizedInput = sanitizedInput.replace(onEventRegex, "");
    return sanitizedInput;
  };

  //Validate the form
  const validate = () => {
    const newErrors: {
      emailFrom?: string;
      subject?: string;
      message?: string;
    } = {};

    if (!subject || subject.length > 36) {
      newErrors.subject = "Nama harus diisi dan tidak lebih dari 36 karakter."; // Length check
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailFrom.trim()) {
      newErrors.emailFrom = "Email harus diisi.";
    } else if (!emailFrom || !emailRegex.test(emailFrom)) {
      newErrors.emailFrom = "Format email tidak valid.";
    }

    const sanitizedMessage = sanitizeInput(message);
    if (!message || message.length > 255 || message.length == 0) {
      newErrors.message =
        "Pesan harus diisi dan tidak lebih dari 255 karakter.";
    } else if (sanitizedMessage !== message) {
      newErrors.message = "Pesan mengandung konten yang tidak valid.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (!validate()) return;

    const { question, correctAnswer } = generateMathQuiz();

    //Display Math Quiz on Sweet Alert
    const { value: userAnswer } = await Swal.fire({
      title:
        '<span class="text-xl text-grey-900">Silahkan jawab pertanyaan berikut</span>',
      // text: question,
      html: `<p class="text-2xl font-bold text-gray-700">${question}</p>`,
      input: "text",
      inputPlaceholder: "jawaban anda",
      showCancelButton: true,
      denyButtonText: "Batal",
      cancelButtonText: "Batal",
      confirmButtonText: "Jawab",
      inputValidator: (value) => {
        if (!value) {
          return "Jawab pertanyaan dulu!";
        }
        if (isNaN(Number(value))) {
          return "Input angka!";
        }
      },
    });

    //Cancel Popup/Alert
    if (!userAnswer) return;

    //Check Math Quiz Answare
    if (Number(userAnswer) !== correctAnswer) {
      Swal.fire({
        title: "Salah!",
        text: "Jawaban anda salah! silahkan ulangi dengan jawaban yang benar.",
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    //Check for Rate Limiter
    const now = Date.now();
    const emailKey = emailFrom || "default";

    if (rateLimiter[emailKey] && now - rateLimiter[emailKey] < 60000) {
      Swal.fire({
        title: "Gagal!",
        text: "Tunggu 60 detik sebelum mencoba lagi",
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    rateLimiter[emailKey] = now;

    //Final Success
    setIsLoading(true);
    Swal.fire({
      // title: "Loading ...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await fetch("/api/node-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ emailFrom, subject, message }),
    });

    const data = await res.json();

    if (res.ok) {
      // setResponseMessage("Email sudah berhasil terkirim");
      setIsLoading(false);
      Swal.fire({
        title: "Sukses!",
        text: "Email sudah berhasil terkirim",
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
      });
      setEmailFrom("");
      setSubject("");
      setMessage("");
    } else {
      // setResponseMessage(`Error: ${data.error}`);
      Swal.fire({
        title: "Gagal!",
        text: "Email gagal terkirim",
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
      setIsLoading(false);
    }
  };

  return (
    <div className="rounded-md bg-[#F9F9FC] px-6 py-11">
      <div className="flex flex-col">
        <h1 className="text-4xl">Kirim Pesan</h1>
        <span className="w-4/5">
          Jika Anda memiliki pertanyaan atau membutuhkan informasi lebih lanjut
          mengenai JDIH DPRD Provinsi Bali, silakan kirim pesan melalui form
          berikut
        </span>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="mt-4 flex-col">
          <div className="mb-2">
            <label
              htmlFor="example-textfield"
              className="mb-2 block text-sm font-semibold text-gray-700"
            >
              Nama
            </label>
            <input
              id="example-textfield"
              type="text"
              className={`w-full appearance-none rounded-md border border-[#F5F5F5] px-3 py-2 leading-tight text-gray-700 focus:outline-none ${
                errors.subject ? "border-red-500" : ""
              }`}
              placeholder="Masukan nama Anda"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              // required
            />
            {errors.subject && (
              <small className="text-red-500">{errors.subject}</small>
            )}
          </div>
          <div className="mb-2">
            <label
              htmlFor="example-textfield"
              className="mb-2 block text-sm font-semibold text-gray-700"
            >
              Email
            </label>
            <input
              id="example-textfield"
              type="email"
              className={`w-full appearance-none rounded-md border border-[#F5F5F5] px-3 py-2 leading-tight text-gray-700 focus:outline-none ${
                errors.emailFrom ? "border-red-500" : ""
              }`}
              placeholder="Masukan email Anda"
              value={emailFrom}
              onChange={(e) => setEmailFrom(e.target.value)}
              // required
            />
            {errors.emailFrom && (
              <small className="text-red-500">{errors.emailFrom}</small>
            )}
          </div>
          <div className="mb-2">
            <label
              htmlFor="example-textfield"
              className="mb-2 block text-sm font-semibold text-gray-700"
            >
              Pesan
            </label>
            <textarea
              id="example-textfield"
              className={`w-full appearance-none rounded-md border border-[#F5F5F5] px-3 py-2 leading-tight text-gray-700 focus:outline-none ${
                errors.message ? "border-red-500" : ""
              }`}
              placeholder="Masukan pesan atau pertanyaan yang ingin Anda sampaikan"
              value={message}
              onChange={(e) => setMessage(sanitizeInput(e.target.value))}
              // required
            />
            {errors.message && (
              <small className="text-red-500">{errors.message}</small>
            )}
          </div>
        </div>
        <button
          type="submit"
          className="rounded-xl bg-primary px-4 py-2 text-white"
        >
          <div className="flex items-center justify-center gap-2">
            Kirim Pesan
            <img
              src="assets/icons/u_arrow-up-right.png"
              alt="send-icon"
              className="h-5 w-5"
            ></img>
          </div>
        </button>
      </form>
    </div>
  );
};

export default Mail;
