import { useEffect, useState } from "react";
import BarChart from "../atoms/barChart";
import HorizontalBarChart from "../atoms/horizontalBarChart";
import StatisticRepository, {
  CategoryResponse,
  GovAffairResponse,
} from "@/src/repositories/statisticRepository";
import SkeletonChart from "../atoms/skeletonChart";
import Constant from "@/src/helpers/constant";

const BarChartStatistic = () => {
  const startYear: number = Constant.DEFAULT_START_YEAR;
  const endYear: number = Constant.CURRENT_YEAR;
  const [chartData, setChartData] = useState<any>(null);
  const [chartTitle, setChartTitle] = useState<string>("");

  const [horizontalChartData, setHorizontalChartData] = useState<any>(null);
  const [horizontalChartTitle, setHorizontalChartTitle] = useState<string>("");

  const statisticRepository = new StatisticRepository();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch vertical statistics
        const response: CategoryResponse =
          await statisticRepository.categoryChart(startYear, endYear);
        const series = response.data.series;
        const yaxis = response.data.yaxis;

        // Extract unique years for labels
        const years = Array.from(
          new Set(series.flatMap((s) => s.data.map((d) => d.x))),
        );

        // Prepare datasets for the chart
        const datasets = series.map((item, index) => ({
          label: item.name,
          data: years.map(
            (year) => item.data.find((d) => d.x === year)?.y || 0,
          ),
          backgroundColor: `rgba(${index * 60 + 80}, 150, 200, 0.8)`,
        }));

        // Set chart title from yaxis
        const title = yaxis[0]?.title?.text || "Default Chart Title";
        setChartTitle(title);
        setChartData({ labels: years, datasets });

        // Fetch horizontal statistics
        const horizontalResponse: GovAffairResponse =
          await statisticRepository.govAffairChart(startYear, endYear);
        const horizontalSeries = horizontalResponse.data.series;
        const horizontalYAxis = horizontalResponse.data.yaxis;

        // Prepare datasets for the horizontal chart
        const horizontalYears = Array.from(
          new Set(horizontalSeries.flatMap((s) => s.data.map((d) => d.x))),
        );
        const horizontalDatasets = horizontalSeries.map((item, index) => ({
          label: item.name,
          data: horizontalYears.map(
            (year) => item.data.find((d) => d.x === year)?.y || 0,
          ),
          backgroundColor: `rgba(${index * 60 + 80}, 150, 200, 0.8)`,
        }));

        const horizontalTitle =
          horizontalYAxis?.title?.text || "Default Horizontal Chart Title";
        setHorizontalChartTitle(horizontalTitle);
        setHorizontalChartData({
          labels: horizontalYears,
          datasets: horizontalDatasets,
        });
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };

    fetchData();
  }, []);

  if (!chartData || !horizontalChartData) return <SkeletonChart />;

  return (
    <div className="justify-center">
      <h2 className="mb-6 text-center text-4xl font-bold">Statistik</h2>
      <div className="flex">
        <div className="w-full xl:w-1/2">
          <BarChart data={chartData} title={chartTitle} />
        </div>
        <div className="w-full xl:w-1/2">
          <HorizontalBarChart
            data={horizontalChartData}
            title={horizontalChartTitle}
          />
        </div>
      </div>
    </div>
  );
};

export default BarChartStatistic;
